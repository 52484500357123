
import Founder1 from '@/components/svg/team/FounderSVG1.vue'
import Founder2 from '@/components/svg/team/FounderSVG2.vue'
import Founder3 from '@/components/svg/team/FounderSVG3.vue'
import Founder4 from '@/components/svg/team/FounderSVG4.vue'
import bodytext from '@/mixins/bodytext'
import BioSVG from '@/components/svg/team/BioSVG.vue'
import BodyWrapOuter from '@/components/wrappers/BodyWrapOuter.vue'
import BodyWrap from '@/components/wrappers/BodyWrap.vue'
import scrambleText from '@/mixins/gsap-anims.js'
export default {
  components: {
    Founder1,
    Founder2,
    Founder3,
    Founder4,
    BioSVG,
    BodyWrapOuter,
    BodyWrap,
  },
  mixins: [bodytext, scrambleText],
  props: {
    member: {
      type: Object,
      default: () => {},
    },
    detail: {
      type: Number,
      default: 0,
    },
    memberCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tl: null,
      in: null,
      out: null,
      hover: null,
      isAnimating: true,
    }
  },
  computed: {
    detailType() {
      switch (this.detail) {
        case 0:
          return 'Founder1'
        case 1:
          return 'Founder2'
        case 2:
          return 'Founder3'
        case 3:
          return 'Founder4'
        default:
          return 'Founder1'
      }
    },
  },
  beforeMount() {
    this.$nuxt.$on('go', this.anim)
  },
  mounted() {
    this.in = this.$gsap.timeline({
      defaults: { ease: 'custom.1' },
      scrollTrigger: {
        trigger: this.$el,
        // toggleActions: 'play reset play reverse',
        start: 'top center',
      },
      onComplete: () => {
        this.isAnimating = false
        this.$emit('inComplete')
      },
    })
    this.in.scrollTrigger.disable()

    this.$refs.svg && this.in.from(this.$refs.svg.$el, { opacity: 0, rotate: -45, duration: 0.5 }, '>')
    this.$refs.img && this.in.from(this.$refs.img.$el, { opacity: 0, duration: 0.1 }, '<')
    if (this.$refs.name) {
      const words = new this.$SplitText(this.$refs.name, { type: 'chars, words' })
      this.in.from(words.chars, { opacity: 0.2, duration: 0.2, stagger: 0.02 }, '<')
    }
    this.$refs.role && this.in.from(this.$refs.role, { opacity: 0, duration: 0.1 }, '>')
    //
    if (this.$refs.bio && !this.$screen.lg) {
      this.$refs.bio && this.in.from(this.$refs.bio, { opacity: 0.2, duration: 0.2 }, '<')
      // // this.in.add(this.scrambleText(this.$refs.bio, 0.0015))
      // // this.in.to(this.$refs.bio, { duration: 1, scrambleText:{ text: this.$refs.bio.innerHTML, chars:01, revealDelay:0.5, speed:0.3}})
      // this.in.from(this.$refs.bio, { duration: 1, scrambleText: { text: this.$refs.bio.innerHTML, chars: '01' } })
    }

    // in timeline
    this.out = this.$gsap.timeline({
      defaults: { ease: 'custom.1' },
      paused: true,
      onComplete: () => {
        this.$emit('outComplete')
      },
    })
    this.$refs.svg && this.out.to(this.$refs.svg.$el, { opacity: 0, rotate: -45 }, '<')
    this.$refs.name && this.out.to(this.$refs.name, { opacity: 0, duration: 0.1 }, '<')
    this.$refs.role && this.out.to(this.$refs.role, { opacity: 0, duration: 0.1 }, '>')

    this.$refs.bio && this.out.to(this.$refs.bio, { opacity: 0.2, duration: 0.2 }, '>')
    this.$refs.img && this.out.to(this.$refs.img.$el, { opacity: 0, duration: 0.1 }, '>')

    // hover timeline
    this.hover = this.$gsap.timeline({
      defaults: { ease: 'custom.1' },
      paused: true,
    })
    this.$refs.svg && this.hover.to(this.$refs.svg.$el, { rotate: -45, duration: 0.2 })
    this.$refs.img && this.hover.to(this.$refs.img.$el, { opacity: 0.1, duration: 0.2 }, '<')
    this.$refs.hiddenBio && this.hover.to(this.$refs.hiddenBio, { opacity: 1, duration: 0.2 }, '>')
  },
  beforeDestroy() {
    this.$nuxt.$off('go', this.anim)
  },
  methods: {
    hoverOn() {
      !this.isAnimating && this.hover.play()
    },

    hoverOff() {
      !this.isAnimating && this.hover.reverse()
    },
    anim() {
      this.in.scrollTrigger.enable()
    },
  },
}
