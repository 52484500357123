
import BtnMain from '@/components/parts/button/BtnMain.vue'
import BtnSecondary from '@/components/parts/button/BtnSecondary.vue'
import ArrowRightSVG from '@/components/svg/icon/ArrowRightSVG.vue'
import Founder from '@/components/parts/team/Founder.vue'
import FounderDetail1SVG from '@/components/svg/team/FounderDetail1SVG.vue'
import FounderDetail2SVG from '@/components/svg/team/FounderDetail2SVG.vue'
import Pagination from '@/components/parts/swiper/Pagination.vue'
import BodyWrapOuter from '@/components/wrappers/BodyWrapOuter.vue'
import bodytext from '@/mixins/bodytext'
import scrambleText from '@/mixins/gsap-anims.js'
export default {
  components: {
    BtnMain,
    Founder,
    FounderDetail1SVG,
    FounderDetail2SVG,
    Pagination,
    BodyWrapOuter,
    BtnSecondary,
    ArrowRightSVG,
  },
  mixins: [bodytext, scrambleText],
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // isMounted: false,
      groupIndex: 0,
      nextIndex: 0,
      dragging: false,
      animating: false,
      tl: null,
      infotl: null,
      initial: true,
    }
  },

  computed: {
    // groupSize() {
    //   if (this.$screen.sm && this.$screen.md && this.$screen.lg) {
    //     return 4
    //   } else {
    //     return 1
    //   }
    // },
    // startEnd() {
    //   return {
    //     start: this.groupIndex * this.groupSize,
    //     end: this.groupSize + this.groupIndex * this.groupSize,
    //   }
    // },
    // filterPosts() {
    //   // if (this.isMounted) {
    //   //   const team = { ...this.section.members }
    //   //   return team.data.slice(this.startEnd.start, this.startEnd.end)
    //   // } else {
    //   //   return null
    //   // }
    //   /// const team = { ...this.section.members }
    //   return [this.section.members.data[this.groupIndex]] // .slice(this.startEnd.start, this.startEnd.end)
    // },
  },
  watch: {
    groupSize() {
      this.groupIndex = 0
      this.init()
    },
  },
  beforeMount() {
    this.$nuxt.$on('go', this.anim)
    // this.$nuxt.$on('screenResized', this.init)
  },
  mounted() {
    // this.isMounted = true
    this.init()
  },
  beforeDestroy() {
    this.$nuxt.$off('go', this.anim)
    // this.$nuxt.$on('screenResized', this.init)
  },

  methods: {
    init() {
      // this.$nextTick(() => {
      this.setupObs() // disable on desktop
      this.setupST()
      // })
    },
    stagger(i) {
      let pos = ''
      if (i % 2 === 0) {
        // even
        pos = 'lg:pr-32'
        if (i > 0) pos += ' lg:-mt-64'
      } else {
        // odd
        pos = i === 1 ? 'lg:mt-144 lg:pr-32' : 'lg:mt-64 lg:pr-32'
      }
      return pos
    },

    setupObs() {
      const self = this
      if (this.$refs.drag) {
        this.$Draggable.create(this.$refs.drag, {
          type: 'x',
          bounds: this.$el,
          dragResistance: 0.75,
          edgeResistance: 1,
          inertia: true,
          zIndexBoost: false,
          snap: () => {
            return 0
          },
          onDrag() {
            if (this.x > 12) return self.prev()
            if (this.x < -12) return self.next()
          },
        })
      }
    },

    setupST() {
      if (this.tl) this.tl.kill()
      this.tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: this.$el,
          start: 'top center',
        },
      })
      this.initial && this.tl.scrollTrigger.disable()

      if (this.$refs.heading) {
        const splitWord = new this.$SplitText(this.$refs.heading, { type: 'chars, words' })
        this.tl.from(
          splitWord.words,
          {
            translateX: 64,
            opacity: 0,
            duration: 0.3,
            stagger: 0.05,
            // onComplete: () => {
            //   splitWord.revert()
            // },
          },
          '<'
        )
      }

      if (this.$refs.bodyWrap) {
        this.infotl = this.$gsap.timeline({
          scrollTrigger: {
            trigger: this.$refs.info,
            // toggleActions: 'play reset play reverse',
            start: 'top center',
            // markers: true,
          },
        })
        this.initial && this.infotl.scrollTrigger.disable()
        this.infotl.from(this.$refs.bodyWrap.$el, { opacity: 0, duration: 0.2 }, 0)
        this.infotl.from(this.$refs.bodyWrap.$el, { scale: 0.2, transformOrigin: 'center', duration: 0.3 }, '<')
        this.infotl.add(this.scrambleText(this.$refs.bodyText, 0.015), '<')
        this.infotl.scrollTrigger.disable()
      }
      this.$emit('sectionMounted')
    },

    anim() {
      this.initial = false
      this.tl.scrollTrigger.enable()
      this.$refs.bodyWrap && this.infotl.scrollTrigger.enable()
    },

    animateOut() {
      this.$refs.team.map((t) => t.out.play())
    },

    animateIn() {
      this.$refs.team.map((t) => t.in.play())
    },

    animate(forwards = true, index = null) {
      if (this.animating) return

      let nextIndex = 0
      if (index !== null) {
        nextIndex = index
      } else {
        nextIndex = forwards ? this.groupIndex + 1 : this.groupIndex - 1
      }

      if (!forwards && nextIndex < 0) return
      if (forwards && nextIndex === 4) return

      this.animating = true
      this.animateOut()
      this.nextIndex = nextIndex
    },

    outComplete() {
      this.groupIndex = this.nextIndex
      this.$nextTick(() => {
        this.animateIn()
      })
    },

    inComplete() {
      this.animating = false
    },

    next() {
      this.animate(true)
    },

    prev() {
      this.animate(false)
    },

    goto(index) {
      if (index >= this.groupIndex) {
        this.animate(true, index)
      } else {
        this.animate(false, index)
      }
    },
  },
}
