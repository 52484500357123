
import BulletSVG from '@/components/svg/swiper/BulletSVG.vue'
import ActiveSVG from '@/components/svg/swiper/ActiveSVG.vue'
import BulletHoverSVG from '~/components/svg/swiper/BulletHoverSVG.vue'
export default {
  components: {
    BulletSVG,
    ActiveSVG,
    BulletHoverSVG,
  },
  props: {
    posts: {
      type: Array,
      default: () => {},
    },
    groupSize: {
      type: Number,
      default: 1,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    bullets() {
      return Math.ceil(this.posts.length / this.groupSize)
    },
  },
  methods: {
    goto(index) {
      this.$emit('goto', index - 1)
    },
  },
}
