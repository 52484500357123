export default {
  methods: {
    scrambleText(el, dur) {
      const scrambleTimeline = this.$gsap.timeline()
      const splitText = new this.$SplitText(el, {
        type: 'words,lines',
      })
      splitText.words.forEach((char, index) => {
        // console.log('el', char)
        scrambleTimeline
          .from(
            char,
            {
              opacity: 0,
              duration: 0.02,
            },
            '<+=0.05'
          )
          .to(
            char,
            {
              duration: 0.3,
              scrambleText: {
                text: char.innerHTML,
                chars: '01 ',
                // revealDelay: 0.5,
                // tweenLength: false,
                // delimiter: ' ',
              },
            },
            '<'
            // index * dur
          )
      })

      return scrambleTimeline
    },
  },
}
